import styled from 'styled-components'
import React from 'react'

import { breakpoints } from '../ui-kit/globalValue'
import { P } from 'components/ui-kit/P'
import { useTranslations } from 'i18n/useTranslations'

const Date = styled(P)`
    overflow: hidden;
    text-align: right;
    font-size: 20px;
    margin-top: 10px;
    ::before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        box-sizing: border-box;
        width: 100%;
        height: 3px;
        background: #222;
        border: solid #fff;
        border-width: 0 10px;
        margin-left: -100%;
        @media (min-width: ${breakpoints.tablet}) {
            margin-left: -30%;
        }
    }
    @media (min-width: ${breakpoints.tablet}) {
        width: 50%;
        padding: 5px 0;
        font-size: 24px;
    }
`
export const AboutHistoryElementDate = (props) => {
    const translations = useTranslations()
    return (
        <Date>
            {props.children} {translations.year}
        </Date>
    )
}
